import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import ServiceCard from "./service_card"
import ThemeContext from "../context/ThemeContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faPinterestP,
} from "@fortawesome/free-brands-svg-icons"

import {
  faPiggyBank,
  faFighterJet,
  faTrophy,
  faHeadset,
  faMouse,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"

const services_links = [
  {
    title: "Website Designing",
    linkto: "/services/web-designing",
    description:
      "We create beautiful, functional, and user-friendly websites that help you achieve your business goals.",
  },
  {
    title: "Web Development",
    linkto: "/services/web-development",
    description:
      "We build custom web applications that help businesses grow and succeed.",
  },
  {
    title: "Digital Marketing",
    linkto: "/services/digital-marketing",
    description:
      "Advertise your business on Search Engines, various websites and Social media like FB, Insta, Whatsapp",
  },
  {
    title: "Corporate Branding",
    linkto: "/services/corporate-branding",
    description:
      "Get a Brand Identity & stand out on the crowd. Get a logo, banners, brochures and credibility with our expertise.",
  },
  {
    title: "Mobile Apps Development",
    linkto: "/services/mobile-apps-development",
    description:
      "We create custom mobile apps that help businesses reach their target audience and grow their bottom line.",
  },
  {
    title: "E-commerce Development",
    linkto: "/services/ecommerce-develoment",
    description:
      "Bring your business online, Start Selling Online! Get a Brand New Online Store for your business.",
  },
  {
    title: "IT Consultancy",
    linkto: "/services/it-consultancy",
    description:
      "We help businesses improve their IT infrastructure and operations for better security, sustainability & scalability.",
  },
  {
    title: "SEO - Search Engine Optimization",
    linkto: "/services/seo-search-engine-optimization",
    description:
      "Increase Visitors! Increase Business! Establish a great index position on Google, Yahoo with our SEO experts. ",
  },
  {
    title: "Graphics Designing",
    linkto: "/services/graphics-designing",
    description:
      "Yes Design Matters!! We create visually stunning graphics that communicate your message effectively.",
  },
  {
    title: "AI - Artificial Intelligence",
    linkto: "/services/graphics-designing",
    description:
      "We help businesses build and deploy AI-powered (Artificial Intelligence) solutions that solve real-world problems",
  },
  {
    title: "Cloud Classrooms",
    linkto: "/services/graphics-designing",
    description:
      "Cloud-based classroom solutions that make learning more engaging and effective. Save Money, Serve More Students.",
  },
  {
    title: "Smart Computing Lab",
    linkto: "/services/graphics-designing",
    description:
      "Smart computing lab solutions that empower students to learn and create with Hands-on learning experiences.",
  },
]

const IncServices = props => (
  <ThemeContext.Consumer>
    {theme => (
      <section
        className={[
          theme.dark ? " dark " : " background-grey ",
          " p-b-20 m-b-0 mb-0",
          " content ",
        ]}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div
                className={
                  theme.dark
                    ? "heading-text text-light"
                    : "heading-text heading-gradient"
                }
              >
                <h2 className="text-center mb-2">
                  <span>{props.title}</span>
                </h2>
                <p className="text-center mb-5">{props.subtitle}</p>
              </div>
            </div>
          </div>
          <div className="row">
            {services_links.map((post, i) => (
              <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
                <div className={theme.dark ? "post-item" : "post-item"}>
                  <div
                    className={
                      theme.dark ? "post-item-wrap dark-3" : "post-item-wrap"
                    }
                  >
                    <div className="post-item-description">
                      <h2 className={theme.dark ? "text-white" : "text-dark"}>
                        <a href="#">{post.title}</a>
                      </h2>
                      <p
                        className={
                          theme.dark ? "text-gray-300" : "text-gray-500"
                        }
                      >
                        {post.description}
                      </p>
                      <Link
                        to={post.linkto}
                        className={
                          theme.dark
                            ? "btn btn-rounded btn-light"
                            : "btn btn-outline btn-dark btn-rounded shadow"
                        }
                      >
                        Get Started <FontAwesomeIcon icon={faChevronRight} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    )}
  </ThemeContext.Consumer>
)

export default IncServices
