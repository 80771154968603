import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import IncServices from "../components/inc_services"

import ThemeContext from "../context/ThemeContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  faPiggyBank,
  faFighterJet,
  faTrophy,
  faHeadset,
  faMouse,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"

const IndexPage = () => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout isHeader="false">
          <Seo title="Techimpace Best Digital Agency for Website Designing, Web Development and Software Development" />
          <section id="welcome" className="p-b-0 bg-festive-diwali">
            <div className="container">
              <div
                className="heading-text heading-section festive-heading text-center m-b-40 animate__animated animate__fadeInUp visible"
                data-animate="fadeInUp"
              >
                <StaticImage
                  src="../images/festive-diwali.png"
                  alt="Logo"
                  quality="100"
                  height="300"
                  width="300"
                />
                <h2 className="text-white">Let's Warm Up Your Business</h2>
                <span className="lead">
                  we have the power to gear up your business with the latest
                  innovation &amp; technology.
                </span>
              </div>
              <div
                className="row animate__animated animate__fadeInUp visible"
                data-animate="fadeInUp"
              >
                <div className="col-lg-12"></div>
              </div>
            </div>
          </section>
          <IncServices
            title="Our Services"
            subtitle="All The Advance Digital Solutions You Need To Fulfill Your
                  Dreams"
          />

          <div
            className={
              theme.dark
                ? "call-to-action p-t-100 p-b-100  mb-0 bg-branding"
                : "call-to-action p-t-100 p-b-100  mb-0 call-to-action-dark"
            }
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-10">
                  <h3>Confused!! with our too many services?</h3>
                  <p>
                    Don't worry, talk to our experts regarding your dream
                    project &amp; get free a quotation
                  </p>
                </div>
                <div className="col-lg-2">
                  <a
                    className={theme.dark ? "btn btn-dark" : "btn btn-primary"}
                    href="#"
                  >
                    Call us now!
                  </a>
                </div>
              </div>
            </div>
          </div>
          <section className={theme.dark ? " dark dark-5 " : " light "}>
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-lg-1 col-md-12 col-sm-12 mb-22">
                  <h4>About us</h4>
                  <h2>We strive for perfection and simplicity</h2>
                  <p className="lead">
                    Let us maximize your business potential with the latest and
                    innovative technologies.
                  </p>
                  <p className="leads">
                    Anchored by industry-experienced professionals, TECHIMPACE
                    is an IT solution provider serving from 2008 and is engaged
                    in application development and e-commerce solutions, website
                    development, Smart device applications, support, and
                    maintenance services.
                  </p>
                  <Link
                    to="/about-us"
                    className={
                      theme.dark
                        ? " btn btn-light btn-outline btn-rounded "
                        : " btn btn-dark btn-outline btn-rounded "
                    }
                  >
                    Know More About Us
                  </Link>
                </div>
                <div className="col-lg-6 offset-1 m-t-40">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>
                        <FontAwesomeIcon icon={faPiggyBank} /> Cost Effectives
                      </h4>
                      <p>
                        We provide a cost effective solution to outsource your
                        IT requirements offering a wide range of services, each
                        tailored to meet the specific needs of your business.
                      </p>
                    </div>

                    <div className="col-lg-6">
                      <h4>
                        <FontAwesomeIcon icon={faFighterJet} /> Advance Features
                      </h4>
                      <p>
                        We endeavor to offer you the most advanced features
                        (solutions) in order to acquire your maximum
                        satisfaction. We are the masters in offering advance
                        &amp; effective IT development solutions.
                      </p>
                    </div>

                    <div className="col-lg-6">
                      <h4>
                        <FontAwesomeIcon icon={faTrophy} /> Award Winning Team
                      </h4>
                      <p>
                        We have an award-winning in-house sales, designing, and
                        production team. They’re Simply the Best! We think very
                        highly of our staff and services, so it’s always great
                        when that’s recognized by those outsides of our
                        organization, too!
                      </p>
                    </div>

                    <div className="col-lg-6">
                      <h4>
                        <FontAwesomeIcon icon={faHeadset} /> Excellent Support
                      </h4>
                      <p>
                        Our highly trained support teams are always focused on
                        providing the best possible support using the latest
                        technology and remote access tools. Our support teams
                        have consistently exceeded customer expectations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            id="section2"
            className="fullscreen text-light bg-branding-wine"
          >
            <div className="container">
              <div className="container-fullscreen">
                <div className="text-middle">
                  <div className="text-center">
                    <h3 className="text-medium">
                      WE CREATE ONLY THE BEST THINGS
                    </h3>
                    <p className="lead m-b-80">
                      Thats why we have been chosen by thousands...
                    </p>
                    <div className="row">
                      <div className="col-lg-3 col-md-6">
                        <div
                          className={
                            theme.dark ? "card dark-4" : "card text-dark"
                          }
                        >
                          <StaticImage
                            className="card-img-top"
                            src="../images/brands/academica.png"
                            alt="Academica"
                          />
                          <div className="card-body">
                            <h4 className="card-title">Academica</h4>
                            <p className="card-text">
                              A Cloud Academic Automation System for Schools,
                              Colleges & educational organizations
                            </p>
                            <a
                              target="_blank"
                              href="https://www.academicaerp.com"
                              className="btn btn-danger"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* Item End */}
                      <div className="col-lg-3 col-md-6">
                        <div
                          className={
                            theme.dark ? "card dark-4" : "card text-dark"
                          }
                        >
                          <StaticImage
                            className="card-img-top"
                            src="../images/brands/digiadmission.png"
                            alt="Academica"
                          />
                          <div className="card-body">
                            <h4 className="card-title">Digiadmission</h4>
                            <p className="card-text">
                              Secured & Scalable Online Admission Platform which
                              can handle N number of users at a time
                            </p>
                            <a
                              target="_blank"
                              href="https://www.digiadmission.com"
                              className="btn btn-danger"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* Item End */}
                      <div className="col-lg-3 col-md-6">
                        <div
                          className={
                            theme.dark ? "card dark-4" : "card text-dark"
                          }
                        >
                          <StaticImage
                            className="card-img-top"
                            src="../images/brands/gymbim.png"
                            alt="Academica"
                          />
                          <div className="card-body">
                            <h4 className="card-title">GYMBIM</h4>
                            <p className="card-text">
                              A Cloud GYM Management & Business Analytics System
                              to increase revenue and growth
                            </p>
                            <a
                              target="_blank"
                              href="https://www.gymbim.com"
                              className="btn btn-danger"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* Item End */}
                      <div className="col-lg-3 col-md-6">
                        <div
                          className={
                            theme.dark ? "card dark-4" : "card text-dark"
                          }
                        >
                          <StaticImage
                            className="card-img-top"
                            src="../images/brands/haperp.png"
                            alt="Academica"
                          />
                          <div className="card-body">
                            <h4 className="card-title">HAPERP</h4>
                            <p className="card-text">
                              Hotel Automation Platform with Cloud Kitchen,
                              Contactless Menucard & much more
                            </p>
                            <a
                              target="_blank"
                              href="https://www.haperp.com"
                              className="btn btn-danger"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* Item End */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className={
              theme.dark ? "p-0 dark bg-dark text-light" : "p-0 background-grey"
            }
          >
            <div className="container">
              <div className="row">
                <div className="heading-text heading-section mt-5 text-center">
                  <h2>Our Valuable Clients</h2>
                </div>
              </div>
            </div>
            <div className="row p-4">
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/stjcollege.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/stxavier.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/sbi.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/perengovtcollege.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/mcsdhupguri.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/innovus.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/himlak.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/sfsgalsi.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/paradise.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/suruchi.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/aerie.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/bmh.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/xpose.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/ethina.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/nysdc.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/omf.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/st-mary.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/dynamic-computers.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/bgh.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/hfs_nuta.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
              <div className="col-xl-1 col-lg-2 col-md-2 col-sm-3 mb-4">
                <StaticImage
                  src="../images/clients/sfs_bahalpur.png"
                  alt="Techimpace Client Logo"
                  className="img-fluid img-thumbnail clients-logo"
                />
              </div>
            </div>
            <div
              className={
                theme.dark
                  ? "call-to-action p-t-100 p-b-100  mb-0 mt-5 dark-3 text-light"
                  : "call-to-action p-t-100 p-b-100  mb-0 mt-5 bg-tiblue text-light"
              }
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-10">
                    <h3>Interested Huhh? Let's meet then</h3>
                    <p>
                      Let's plan for your success together, start your journey
                      with us
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <a
                      className={
                        theme.dark
                          ? "btn btn-light btn-rounded btn-outline "
                          : "btn bg-light text-primary"
                      }
                      href="https://calendly.com/techimpace"
                    >
                      Book Appointment
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </ThemeContext.Consumer>
  )
}
export default IndexPage
